import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import { ref, reactive, computed, watch } from "vue";
import $ from "@util/base";
export default {
  __name: 'ConfirmAfterSale',
  props: {
    money: {
      type: Number,
      default: 0
    },
    deposit: {
      type: Number,
      default: 0
    },
    desc: {
      type: String,
      default: ""
    }
  },
  emits: ["submit"],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;
    const formRef = ref(null);
    const form = reactive({
      refundMoney: null,
      depositMoney: null
    });
    watch(() => props.money, val => {
      console.log("refundMoney" + val);
      form.refundMoney = val;
      form.depositMoney = props.deposit;
    });
    const desc = computed(() => {
      return props.desc;
    });
    const dialogVisible = ref(false);

    function show() {
      dialogVisible.value = true;
    }

    __expose({
      show
    });

    const rules = {
      refundMoney: [{
        required: true,
        message: "请输入退款金额",
        trigger: "blur"
      }],
      depositMoney: [{
        required: true,
        message: "请输入退款余额",
        trigger: "blur"
      }]
    };

    function submit() {
      formRef.value.validate(valid => {
        if (valid) {
          dialogVisible.value = false;
          emit("submit", form.refundMoney, form.depositMoney);
        }
      });
    }

    const resetForm = () => {
      formRef.value.resetFields();
    };

    return (_ctx, _cache) => {
      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => dialogVisible.value = $event),
        title: "确认退款",
        width: "35%",
        top: "30vh",
        class: "base_dialog",
        onOpen: _cache[6] || (_cache[6] = $event => resetForm())
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[3] || (_cache[3] = $event => submit())
        }, {
          default: _withCtx(() => [_createTextVNode("确定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "formRef",
          ref: formRef,
          model: form,
          rules: rules,
          "label-width": "120px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "退款金额",
            prop: "refundMoney"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: form.refundMoney,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.refundMoney = $event),
              modelModifiers: {
                number: true
              },
              min: 0,
              precision: 2,
              controls: false,
              "controls-position": "right"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "退还储值金额",
            prop: "depositMoney"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input_number, {
              modelValue: form.depositMoney,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.depositMoney = $event),
              modelModifiers: {
                number: true
              },
              min: 0,
              precision: 2,
              controls: false,
              "controls-position": "right"
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _unref($).isNotEmpty(desc.value) ? (_openBlock(), _createBlock(_component_el_form_item, {
            key: 0,
            label: "退改政策"
          }, {
            default: _withCtx(() => [_withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => desc.value = $event),
              id: "",
              cols: "60",
              rows: "10",
              disabled: ""
            }, null, 512), [[_vModelText, desc.value]])]),
            _: 1
          })) : _createCommentVNode("", true)]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};