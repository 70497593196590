import {POST} from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    list: "contract_list",
    get: "contract_get",
    add: "contract_add",
    del: "contract_del",
    update: "contract_update",
    updateWay: "contract_update_way",
    statusNum: "contract_status_num",
    exportData: "contract_list_export",
    downloadTemplate: "download_contract",
    import: "contract_import",
    typeList:"contract_type_list",
    lock:"contract_lock",
}

const api = {
    exportData(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.type)) {
            paramsData.type = params.type;
        }
        if ($.isNotEmpty(params.contractInfo)) {
            paramsData.contractInfo = params.contractInfo;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }
        if ($.isNotEmpty(params.status) && params.status != "1") {
            paramsData.contractStatus = params.status;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }

        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size

        let requestOptions = {
            path: URL.exportData,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    downloadTemplate(succ) {
        let requestOptions = {
            path: URL.downloadTemplate,
            succ: succ,
        };
        POST(requestOptions);
    },
    import(file, succ, fail) {
        let requestOptions = {
            path: URL.import,
            params: { fileUrl: file },
            isToast: false,
            succ: succ,
            fail: fail
        };
        POST(requestOptions);
    },
    statusNum(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.type)) {
            paramsData.type = params.type;
        }
        if ($.isNotEmpty(params.contractInfo)) {
            paramsData.contractInfo = params.contractInfo;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.contractStatus = params.status;
        }
        let requestOptions = {
            path: URL.statusNum,
            params: paramsData,
            succ: (val) => {
                succ([val.total, val.notRedeemed, val.redeemed]);
            },
        };
        POST(requestOptions);
    },
    list(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.type)) {
            paramsData.type = params.type;
        }
        if ($.isNotEmpty(params.contractInfo)) {
            paramsData.contractInfo = params.contractInfo;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }
        if ($.isNotEmpty(params.status) && params.status != "1") {
            paramsData.contractStatus = params.status;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0];
            paramsData.endTime = params.date[1];
        }

        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    lock(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.id)) {
            paramsData.id = params.id;
        }
        let requestOptions = {
            path: URL.lock,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    typeList(params, succ) {
        let paramsData = {}
        paramsData.page = 1
        paramsData.pageTotal = 100
        let requestOptions = {
            path: URL.typeList,
            params: paramsData,
            isLoading: true,
            succ: val => {
                succ(val.itemList.map(e => {
                    return { label: e.name, value: e.id, type: val.busiType }
                }))
            }
        }
        POST(requestOptions)
    },
    get(equityCode, succ) {
        let requestOptions = {
            path: URL.get,
            params: {equityCode: equityCode},
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    updateWay(equity, succ) {
        let requestOptions = {
            path: URL.updateWay,
            params: equity,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(equity, succ) {
        equity.type = 2;
        let requestOptions = {
            path: URL.add,
            params: equity,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    update(equity, succ) {
        let requestOptions = {
            path: URL.update,
            params: equity,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    }
}
export default api
