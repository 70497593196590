import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  key: 1
};
const _hoisted_3 = {
  key: 0,
  class: "base_main_header"
};
const _hoisted_4 = {
  key: 1,
  class: "base_main_margin_top"
};
import StatusBar from "@com/StatusBar.vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import OrderShipments from "@com/order/OrderShipments.vue";
import CancelOrder from "@com/order/CancelOrder.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import $ from "@util/base";
import configApi from "@api/config";
import api from "@api/order";
export default {
  __name: 'TravelOrder',
  props: {
    type: {
      type: String
    },
    routeName: {
      type: String
    }
  },

  setup(__props) {
    const props = __props;
    const route = useRoute();
    route.watchRouter(props.routeName, () => {
      getData();
    }); // 1022001 未付款、1022002 处理中、1022003 已取消、1022004 未出行、1022005 已完成

    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "处理中",
      value: "1022002",
      total: 0
    }, {
      label: "未出行",
      value: "1022004",
      total: 0
    }, {
      label: "已完成",
      value: "1022005",
      total: 0
    }, {
      label: "订单取消",
      value: "1022003",
      total: 0
    }]);
    const baseOptions = ref([]);
    const base = ref(route.query().base || "");
    const main = ref(route.query().main || "");
    const orderCode = ref(route.query().orderCode || "");
    const travel = ref(route.query().travel || "");
    const name = ref(route.query().name || "");
    const date = ref(route.query().date || []);
    const total = ref(0);
    const data = ref([]);
    getConfig();

    function getConfig() {
      configApi.base(val => {
        baseOptions.value = val;
      });
    }

    function getOperations(data) {
      let arr = [];

      if (props.type == "order") {
        // 1022001 未付款、1022002 处理中、1022003 已取消、1022004 未出行、1022005 已完成
        if (data.orderStatus == "1022001" || data.orderStatus == "1022002" || data.orderStatus == "1022004") {
          if (data.isDeliver == 1) {
            arr = [OPERATIONS.check, OPERATIONS.shipments, OPERATIONS.cancelOrder];
          } else {
            arr = [OPERATIONS.check, OPERATIONS.cancelOrder];
          }
        } else {
          if (data.isDeliver == 1) {
            arr = [OPERATIONS.check, OPERATIONS.shipments];
          } else {
            arr = [OPERATIONS.check];
          }
        }

        if (data.orderStatus == "1022002") {
          arr.push(OPERATIONS.acceptOrder);
        } // 1049001 未签署、1049002 已签署


        if (data.signStatus == '1049002') {
          arr.push(OPERATIONS.contract);
        } //未出行显示回团按钮


        if (data.orderStatus == "1022004") {
          arr.push(OPERATIONS.returned);
        }
      } else {
        arr = [OPERATIONS.check];
      }

      return arr;
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [// {
      //   label: "主订单编号",
      //   prop: "mainOrderCode",
      //   width: 110,
      // },
      {
        label: "订单编号",
        prop: "orderCode",
        width: 110
      }, {
        label: "礼包码编号",
        prop: "exchangeItemCode",
        width: 120
      }, {
        label: "产品名称",
        prop: "travelName"
      }, {
        label: "下单时间",
        prop: "createTime",
        width: 100
      }, {
        label: "订单状态",
        prop: "orderStatusStr",
        width: 100
      }, {
        label: "合同签署状态",
        prop: "signStatusName",
        width: 120,
        type: COLUMN_TYPE.linkText,
        operationsKey: OPERATIONS.linkText
      }, {
        label: "购买数量",
        prop: "sellNum",
        width: 90
      }, {
        label: "归属基地",
        prop: "shopName",
        width: 100
      }, {
        label: "预订人信息",
        prop: "guestStr",
        width: 180,
        type: COLUMN_TYPE.tooltip,
        rows: 3
      }, {
        label: "分销员",
        prop: "agentMemberName",
        width: 90
      }, // {
      //   label: "联系方式",
      //   prop: "phoneNumber",
      //   width: 120,
      // },
      {
        label: "承接人",
        prop: "confirmBy",
        width: 90
      }, {
        label: "承接时间",
        prop: "confirmTime",
        width: 100
      }, {
        label: "核销人",
        prop: "verifierName",
        width: 90
      }, {
        label: "核销时间",
        prop: "verificateTime",
        width: 100
      }]
    };
    const shipmentsRef = ref("");
    const cancelOrderRef = ref(null);
    let selectOpData = null;
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "travelorderdetails",
        query: {
          code: data.orderCode
        }
      });
    });
    opHander.install(OP_KEY.linkText, data => {
      if (data.signStatus == "1049001") {
        $box.input("上传合同编号", '合同编号', data.name, val => {
          api.upContractCode(data.orderCode, val, () => {
            getData();
            $msg.succ("上传成功");
          });
        });
      }
    });
    opHander.install(OP_KEY.shipments, data => {
      selectOpData = data;
      shipmentsRef.value.show();
    });
    opHander.install(OP_KEY.returned, data => {
      $box.open("已回团", "确认已回团吗？", () => {
        api.complete(data.orderCode, () => {
          $msg.succ("已回团成功");
          getData();
        }, msg => {
          $box.open("已回团失败", msg);
        });
      });
    });
    opHander.install(OP_KEY.acceptOrder, data => {
      $box.open("二次已确认", "是否二次已确认？", () => {
        api.confirm("travelGroup", data.orderCode, () => {
          $msg.succ("二次已确认成功");
          getData();
        }, msg => {
          $box.open("二次已确认失败", msg);
        });
      });
    });

    function getShipments(data) {
      api.shipment("travel", {
        orderCode: selectOpData.orderCode,
        expressDetail: data.express // expressName: data.company,
        // expressNo: data.number

      }, () => {
        $msg.succ("发货成功");
        getData();
      });
    }

    opHander.install(OP_KEY.cancelOrder, data => {
      selectOpData = data;
      cancelOrderRef.value.show();
    });
    opHander.install(OP_KEY.contract, data => {
      api.contract(data.orderCode, val => {
        // $.download(val.url, `${data.orderCode}合同`)
        window.open(val.url, '_blank');
      });
    });

    function cancelOrder(data) {
      if (selectOpData != null) {
        api.cancel({
          type: "travel",
          code: selectOpData.orderCode,
          // amount: data.amount,
          remark: data.reason
        }, () => {
          $msg.succ("取消成功");
          getData();
        });
      } else {
        $msg.error("请重新选择记录操作");
      }
    }

    function ticket() {
      $box.input("核销", "核销码", null, val => {
        api.ticket("travel", val, () => {
          $box.open("提示", "核销成功");
          getData();
        }, msg => {
          $box.open("核销失败", msg);
        });
      });
    }

    function searchBase(val) {
      route.replaceQuery("base", val);
    }

    function searchMain(val) {
      route.replaceQuery("main", val);
    }

    function searchOrderCode(val) {
      route.replaceQuery("orderCode", val);
    }

    function searchTravel(val) {
      route.replaceQuery("travel", val);
    }

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    function searchDate(val) {
      route.replaceQuery("date", val);
    }

    getData();

    function getData() {
      getNum();
      getList();
    }

    function getNum() {
      let routeQuery = route.query();
      let params = {
        memberLoginName: routeQuery.login,
        mainOrderCode: routeQuery.main,
        orderCode: routeQuery.orderCode,
        shopCode: routeQuery.base,
        travelName: routeQuery.travel,
        guestName: routeQuery.name,
        status: routeQuery.status
      };

      if ($.isNotEmpty(routeQuery.date)) {
        params.startTime = routeQuery.date[0];
        params.endTime = routeQuery.date[1];
      }

      if (props.type == "member") {
        params.login = route.query().login;
      }

      api.num(params, "travel", val => {
        statusData([val.total, val.unconfirmed, val.unused, val.completed, val.cancel]);
      });
    }

    function statusData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function getList() {
      let routeQuery = route.query();
      let params = {
        type: "travel",
        memberLoginName: routeQuery.login,
        mainOrderCode: routeQuery.main,
        orderCode: routeQuery.orderCode,
        shopCode: routeQuery.base,
        travelName: routeQuery.travel,
        guestName: routeQuery.name,
        status: routeQuery.status,
        date: routeQuery.date,
        page: routeQuery.p,
        size: routeQuery.s
      };
      api.orderList(params, val => {
        total.value = val.total; // val.list = [{orderStatus: "1022001"}, {orderStatus: "1022002"}, {orderStatus: "1022003"}, {orderStatus: "1022004"}, {orderStatus: "1022005"}]

        data.value = val.list.map(e => {
          // 1022001 未付款、1022002 处理中、1022003 已取消、1022004 未出行、1022005 已完成
          switch (e.orderStatus) {
            case "1022001":
              e.status = "未付款";
              break;

            case "1022002":
              e.status = "处理中";
              break;

            case "1022003":
              e.status = "已取消";
              break;

            case "1022004":
              e.status = "未出行";
              break;

            case "1022005":
              e.status = "已完成";
              break;
          }

          return e;
        });
      });
    }

    function getExport() {
      let routeQuery = route.query();
      let params = {
        type: "travel",
        memberLoginName: routeQuery.login,
        mainOrderCode: routeQuery.main,
        orderCode: routeQuery.orderCode,
        shopCode: routeQuery.base,
        travelName: routeQuery.travel,
        guestName: routeQuery.name,
        status: routeQuery.status,
        date: routeQuery.date
      };
      api.export(params, val => {
        $.download(val.url, "旅游会员订单导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [props.type == 'order' ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        onClick: ticket,
        type: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode("核销码核销 ")]),
        _: 1
      })), [[_directive_auth, _ctx.getCurCode('USED')]]) : (_openBlock(), _createElementBlock("div", _hoisted_2)), _createVNode(_component_el_form, {
        inline: true,
        class: "base_search_form",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_date_picker, {
            modelValue: date.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => date.value = $event),
            type: "daterange",
            "range-separator": "~",
            "start-placeholder": "开始时间",
            "end-placeholder": "结束时间",
            "value-format": "YYYY-MM-DD",
            onChange: searchDate
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: base.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => base.value = $event),
            onChange: searchBase,
            placeholder: "请选择基地",
            clearable: ""
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(baseOptions.value, (item, index) => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: index,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: orderCode.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => orderCode.value = $event),
            "onKeyup[native]": searchOrderCode,
            onChange: searchOrderCode,
            placeholder: "订单编号",
            "suffix-icon": _unref(Search),
            clearable: ""
          }, null, 8, ["modelValue", "suffix-icon"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: travel.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => travel.value = $event),
            "onKeyup[native]": searchTravel,
            onChange: searchTravel,
            placeholder: "产品名称",
            clearable: "",
            "suffix-icon": _unref(Search)
          }, null, 8, ["modelValue", "suffix-icon"])]),
          _: 1
        }), _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: name.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => name.value = $event),
            "onKeyup[native]": searchName,
            onChange: searchName,
            placeholder: "预定人",
            clearable: "",
            "suffix-icon": _unref(Search)
          }, null, 8, ["modelValue", "suffix-icon"])]),
          _: 1
        })]),
        _: 1
      })]), _createVNode(StatusBar, {
        data: statusList,
        default: "all"
      }, null, 8, ["data"]), props.type == 'order' ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
        type: "primary",
        text: "",
        onClick: getExport
      }, {
        default: _withCtx(() => [_createTextVNode("导出")]),
        _: 1
      })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])])) : (_openBlock(), _createElementBlock("div", _hoisted_4)), _createVNode(DataCollectTable, {
        data: data.value,
        total: total.value,
        config: config,
        onOperationsHander: _unref(opHander).receive
      }, null, 8, ["data", "total", "onOperationsHander"]), _createVNode(OrderShipments, {
        ref_key: "shipmentsRef",
        ref: shipmentsRef,
        onShipments: getShipments
      }, null, 512), _createVNode(CancelOrder, {
        ref_key: "cancelOrderRef",
        ref: cancelOrderRef,
        onCancelOrder: cancelOrder
      }, null, 512)]);
    };
  }

};