import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    list: "coupon_list",
    add: "coupon_add",
    details: "coupon_detail",
    provide: "coupon_provide",
    export: "coupon_list_export",
    couponUpdateWay: "coupon_update_way",
    couponSendList: "coupon_send_list",
    getQrcode: "get_qrcode",
    update:"coupon_update",
    memberCouponUpdateWay:"member_coupon_update_way",
};

const api = {
    list(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    add(params, succ) {
        // let paramsData = {}
        // paramsData.name = params.name
        // paramsData.startDate = params.date[0]
        // paramsData.endDate = params.date[1]
        // paramsData.moneyType = params.moneyType
        // paramsData.money = params.moneyType === "1010001" ? params.moneyOff.money : params.discountMoney
        // paramsData.limitMoney = params.moneyType === "1010001" ? params.moneyOff.limitMoney : ""
        // paramsData.limitBusiType = params.limitBusiType
        let requestOptions = {
            path: URL.add,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    update(params, succ) {
        let requestOptions = {
            path: URL.update,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { couponCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    provide(params, succ) {
        let paramsData = {}
        paramsData.couponCode = params.couponCode
        paramsData.provideFlag = parseInt(params.provideFlag)
        paramsData.phone = params.phone
        paramsData.tags = params.tags
        paramsData.sendCrowd = parseInt(params.sendCrowd)
        // if (paramsData.provideFlag == 2) {
        //     paramsData.memberLevel = "1039001"
        // }
        let requestOptions = {
            path: URL.provide,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    getQrcode(params, succ) {
        let requestOptions = {
            path: URL.getQrcode,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    export (params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name;
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    stop(code, succ) {
        let paramsData = {};
        if ($.isNotEmpty(code)) {
            paramsData.code = code;
        }
        paramsData.enableStatus = "1001002";
        let requestOptions = {
            path: URL.couponUpdateWay,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    start(code, succ) {
        let paramsData = {};
        if ($.isNotEmpty(code)) {
            paramsData.code = code;
        }
        paramsData.enableStatus = "1001001";
        let requestOptions = {
            path: URL.couponUpdateWay,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    couponSendList(params, succ) {
        let paramsData = {}
        paramsData.couponCode = params.couponCode
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.couponSendList,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    memberCouponUpdateWay(params, succ) {
        let paramsData = {}
        paramsData.couponCode = params.couponCode
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.memberCouponUpdateWay,
            params: params,
            succ: succ,
        };
        POST(requestOptions);
    }
}

export default api;
