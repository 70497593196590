import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page";

const URL = {
    num: "after_sale_status_num",
    list: "after_sale_list",
    details: "",
    export: "after_sale_list_export",
    cancel: "after_sale_cancel",
    confirm: "after_sale_confirm",
    refund: "after_sale_refund",
    takeOver: "after_sale_take_over",
    afterSaleSendCoupon: "after_sale_send_coupon",
};

const api = {
    num(succ) {
        let requestOptions = {
            path: URL.num,
            succ: (val) => {
                succ([
                    val.total,
                    val.pending,
                    val.processing,
                    val.completed,
                    val.cancel,
                ]);
            },
        };
        POST(requestOptions);
    },
    list(params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.shopCode)) {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.afterSaleCode)) {
            paramsData.afterSaleCode = params.afterSaleCode;
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.afterSaleStatus = params.status;
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { giftCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    export (params, succ) {
        let paramsData = {};
        if ($.isNotEmpty(params.shopCode)) {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.afterSaleCode)) {
            paramsData.afterSaleCode = params.afterSaleCode;
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.afterSaleStatus = params.status;
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    cancel(params, succ) {
        let requestOptions = {
            path: URL.cancel,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    confirm(code, money,deposit, succ) {
        let requestOptions = {
            path: URL.confirm,
            params: { afterSaleCode: code, refundMoney: money,depositMoney: deposit },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    refund(params, succ) {
        let requestOptions = {
            path: URL.refund,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    takeOver(code, succ) {
        let requestOptions = {
            path: URL.takeOver,
            params: { afterSaleCode: code },
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    giveCoupon(params, succ) {
        let requestOptions = {
            path: URL.afterSaleSendCoupon,
            params: params,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    }
};

export default api;
