import {
    POST
} from '@net/service.js'
import page from "./page"

const URL = {
    goodsSpecs: { //商品标签
        list: 'sku_sale_attr_config_list',
        add: 'sku_sale_attr_config_add',
        del: 'sku_sale_attr_config_del',
        upd: 'sku_sale_attr_config_update'
    },
    express: { //运费管理
        list: 'postage_templete_list',
        del: 'postage_templete_delete',
    },
    travelLabel: { //旅游标签
        list: 'travel_tag_config_list',
        add: 'travel_tag_config_add',
        del: 'travel_tag_config_del',
        upd: 'travel_tag_config_update'
    },
    goodsLabel: { //商品标签
        list: 'product_tag_config_list',
        add: 'product_tag_config_add',
        del: 'product_tag_config_del',
        upd: 'product_tag_config_update'
    },
    hotelLabel: { //酒店标签
        list: 'hotel_tag_config_list',
        add: 'hotel_tag_config_add',
        del: 'hotel_tag_config_del',
        upd: 'hotel_tag_config_update'
    },
    group: { //旅游参团性质
        list: 'travel_join_config_list',
        add: 'travel_join_config_add',
        del: 'travel_join_config_del',
        upd: 'travel_join_config_update'
    },
    hotelFacilitie: { //酒店设施
        list: 'hotel_facility_config_list',
        add: 'hotel_facility_config_add',
        del: 'hotel_facility_config_del',
        upd: 'hotel_facility_config_update'
    },
    roomFacilitie: { //房间设施
        list: 'hotel_room_catering_config_list',
        add: 'hotel_room_catering_config_add',
        del: 'hotel_room_catering_config_del',
        upd: 'hotel_room_catering_config_update'
    },
    service: { //餐饮服务
        list: 'hotel_catering_config_list',
        add: 'hotel_catering_config_add',
        del: 'hotel_catering_config_del',
        upd: 'hotel_catering_config_update'
    },
    deliveryStation: { //自提点
        list: 'self_delivery_station_list',
        del: 'self_delivery_station_del'
    },
    foreEndFilter: { // 前端筛选
        status: 'travel_filter_enable_get',
        upd: 'travel_filter_enable'
    },
    areaConfig: { //线路区域配置
        list: 'line_area_config_list',
        add: 'line_area_config_add',
        del: 'line_area_config_del',
        upd: 'line_area_config_update'
    },
    lineType: {
        list: 'line_type_config_list',
        add: 'line_type_config_add',
        del: 'line_type_config_del',
        upd: 'line_type_config_update'
    },
    contractLabel: {
        list: 'contract_type_list',
        add: 'contract_type_add',
        del: 'contract_type_del',
        upd: 'contract_type_update'
    }
}

const api = {
    list(type, params, succ) {
        let paramsData = {}
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size

        let requestOptions = {
            path: URL[type].list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    del(type, id, succ) {
        let requestOptions = {
            path: URL[type].del,
            params: { id: id },
            isLoading: false,
            succ: succ
        }
        POST(requestOptions)
    },
    add(type, name, succ) {
        let requestOptions = {
            path: URL[type].add,
            params: { name: name },
            isLoading: false,
            succ: succ
        }
        POST(requestOptions)
    },
    updata(type, id, name, succ) {
        let requestOptions = {
            path: URL[type].upd,
            params: { id: id, name: name },
            isLoading: false,
            succ: succ
        }
        POST(requestOptions)
    },
    statusForeEndFilter(type, succ) {
        let paramsData = {}
            // join内容 tag 属性
        paramsData.type = type === 'group' ? 'join' : 'tag'
        let requestOptions = {
            path: URL.foreEndFilter.status,
            params: paramsData,
            isLoading: false,
            succ: succ
        }
        POST(requestOptions)
    },
    updForeEndFilter(type, isStart, succ) {
        let paramsData = {}
            // join内容 tag 属性
        paramsData.type = type === 'group' ? 'join' : 'tag'
        paramsData.isStart = isStart == 1 ? 0 : 1
        let requestOptions = {
            path: URL.foreEndFilter.upd,
            params: paramsData,
            isLoading: true,
            succ: succ
        }

        POST(requestOptions)
    },
}

export default api
