import {POST} from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    // 状态数统计
    num: {
        goods: "order_product_status_num",
        travelGroup: "order_travel_group_status_num",
        travel: "order_travel_status_num",
        hotel: "order_hotel_status_num",
        line: "order_line_status_num"

    },
    // 列表
    orderList: {
        goods: "order_product_list",
        travelGroup: "order_travel_group_list",
        travel: "order_travel_list",
        hotel: "order_hotel_list",
        line: "order_line_list",
    },
    // 导出
    export: {
        goods: "order_product_list_export",
        travel: "order_travel_list_export",
        hotel: "order_hotel_list_export",
    },
    // 取消订单
    cancel: {
        goods: "order_product_cancel",
        travel: "order_travel_cancel",
        travelGroup: "order_travel_group_cancel",
        hotel: "order_hotel_cancel",
    },
    // 二次已确认
    confirm: {
        travelGroup: "order_travel_group_confirm",
        hotel: "order_hotel_confirm",
    },
    // 自提
    pickup: "order_product_pickup",
    // 核销
    ticket: {
        travel: "order_travel_ticket_code_used",
        hotel: "order_hotel_ticket_code_used"
    },
    // 发货
    shipment: {
        goods: "order_product_shipment",
        travel: "order_travel_shipment"
    },
    // 详情
    detail: {
        goods: "order_product_detail",
        travelGroup: "order_travel_group_detail",
        travel: "order_travel_detail",
        hotel: "order_hotel_detail",
    },
    //酒店确认入住
    hotelConfirm: 'order_hotel_check_in_confirmation',
    // 已回团-团单，弃用
    // returned: "order_travel_group_complete",
    // 签署合同（查看）
    // contract: "fasc_order_travel_task_get",
    contract: "fasc_order_travel_pdf",

    //已回团
    complete: "order_travel_complete",
    //团建订单-状态修改
    orderLineUpdate:"order_line_update",

    upContractCode:"up_contract_code"
};

const api = {
    num(params, type, succ) {
        // let paramsData = {}
        // if ($.isNotEmpty(params.login)) {
        //     paramsData.memberLoginName = params.login;
        // }

        let requestOptions = {
            path: URL.num[type],
            params: params,
            succ: succ,
        };
        POST(requestOptions);
    },
    orderList(params, succ) {
        let paramsData = {};
        if (params.type === "goods") {
            if ($.isNotEmpty(params.orderCode)) {
                paramsData.orderCode = params.orderCode;
            }
            if ($.isNotEmpty(params.recipientName)) {
                paramsData.recipientName = params.recipientName;
            }
        }
        if (params.type === "travel") {
            if ($.isNotEmpty(params.travelName)) {
                paramsData.travelName = params.travelName;
            }
            if ($.isNotEmpty(params.guestName)) {
                paramsData.guestName = params.guestName;
            }
        }
        if (params.type === "travelGroup") {
            if ($.isNotEmpty(params.name)) {
                paramsData.travelName = params.name;
            }
            if ($.isNotEmpty(params.guest)) {
                paramsData.bookingCustomers = params.guest;
            }
        }
        if (params.type === "hotel") {
            if ($.isNotEmpty(params.hotelName)) {
                paramsData.hotelName = params.hotelName;
            }
            if ($.isNotEmpty(params.guestName)) {
                paramsData.guestName = params.guestName;
            }
        }
        if (params.type === "line") {
            if ($.isNotEmpty(params.lineName)) {
                paramsData.lineName = params.lineName;
            }
            if ($.isNotEmpty(params.guestName)) {
                paramsData.guestName = params.guestName;
            }
        }
        if ($.isNotEmpty(params.mainOrderCode)) {
            paramsData.mainOrderCode = params.mainOrderCode;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.memberLoginName)) {
            paramsData.memberLoginName = params.memberLoginName;
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            if (params.type === "travelGroup") {
                paramsData.groupStatus = params.status;
            } else {
                paramsData.orderStatus = params.status;
            }
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        paramsData.page = params.page ? params.page : 1;
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size;
        let requestOptions = {
            path: URL.orderList[params.type],
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    export(params, succ) {
        let paramsData = {};
        if (params.type === "goods") {
            if ($.isNotEmpty(params.orderCode)) {
                paramsData.orderCode = params.orderCode;
            }
            if ($.isNotEmpty(params.recipientName)) {
                paramsData.recipientName = params.recipientName;
            }
        }
        if (params.type === "travel") {
            if ($.isNotEmpty(params.travelName)) {
                paramsData.travelName = params.travelName;
            }
            if ($.isNotEmpty(params.guestName)) {
                paramsData.guestName = params.guestName;
            }
        }
        if (params.type === "travelGroup") {
            if ($.isNotEmpty(params.name)) {
                paramsData.travelName = params.name;
            }
            if ($.isNotEmpty(params.guest)) {
                paramsData.bookingCustomers = params.guest;
            }
        }
        if (params.type === "hotel") {
            if ($.isNotEmpty(params.hotelName)) {
                paramsData.hotelName = params.hotelName;
            }
            if ($.isNotEmpty(params.guestName)) {
                paramsData.guestName = params.guestName;
            }
        }
        if ($.isNotEmpty(params.mainOrderCode)) {
            paramsData.mainOrderCode = params.mainOrderCode;
        }
        if ($.isNotEmpty(params.orderCode)) {
            paramsData.orderCode = params.orderCode;
        }
        if ($.isNotEmpty(params.memberLoginName)) {
            paramsData.memberLoginName = params.memberLoginName;
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode;
        }
        if ($.isNotEmpty(params.status) && params.status != "all") {
            paramsData.orderStatus = params.status;
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        let requestOptions = {
            path: URL.export[params.type],
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    cancel(params, succ) {
        let paramsData = {}
        if (params.type == "travelGroup") {
            paramsData.groupCode = params.code
        } else {
            paramsData.orderCode = params.code
        }
        paramsData.remark = params.remark
        let requestOptions = {
            path: URL.cancel[params.type],
            params: paramsData,
            isLoading: true,
            succ: succ,
        };
        POST(requestOptions);
    },
    confirm(type, code, succ, fail) {
        let paramsData = {};
        if (type == "travelGroup") {
            paramsData.groupCode = code;
        } else {
            paramsData.orderCode = code;
        }

        let requestOptions = {
            path: URL.confirm[type],
            params: paramsData,
            isToast: false,
            succ: succ,
            fail: fail,
        };

        POST(requestOptions);
    },
    pickup(code, succ, fail) {
        let requestOptions = {
            path: URL.pickup,
            params: {pickupCode: code},
            isToast: false,
            succ: succ,
            fail: fail
        };
        POST(requestOptions);
    },
    hotelConfirm(code, succ, fail) {
        let requestOptions = {
            path: URL.hotelConfirm,
            params: {orderCode: code},
            isToast: false,
            succ: succ,
            fail: fail,
        };
        POST(requestOptions);
    },
    ticket(type, code, succ, fail) {
        let requestOptions = {
            path: URL.ticket[type],
            params: {ticketCode: code},
            isToast: false,
            succ: succ,
            fail: fail
        };
        POST(requestOptions);
    },
    shipment(type, params, succ) {
        let requestOptions = {
            path: URL.shipment[type],
            params: params,
            succ: succ,
        };
        POST(requestOptions);
    },
    detail(type, code, afterSaleCode, succ) {
        let paramsData = {
            afterSaleCode: afterSaleCode,
        };
        if (type == "travelGroup") {
            paramsData.groupCode = code;
        } else {
            paramsData.orderCode = code;
        }
        let requestOptions = {
            path: URL.detail[type],
            params: paramsData,
            succ: succ,
        };
        POST(requestOptions);
    },
    // returned(code, succ, fail) {
    //     let requestOptions = {
    //         path: URL.returned,
    //         params: { groupCode: code },
    //         isToast: false,
    //         succ: succ,
    //         fail: fail
    //     };
    //     POST(requestOptions);
    // },
    contract(code, succ) {
        let requestOptions = {
            path: URL.contract,
            params: {orderCode: code},
            isToast: false,
            succ: succ
        };
        POST(requestOptions);
    },
    complete(orderCode, succ, fail) {
        let requestOptions = {
            path: URL.complete,
            params: {
                orderCode: orderCode
            },
            isLoading: true,
            succ: succ,
            fail: fail
        };
        POST(requestOptions);
    },
    confirmOrder(code, status, succ, fail) {
        let paramsData = {};
        paramsData.orderCode = code;
        paramsData.orderStatus = status;
        let requestOptions = {
            path: URL.orderLineUpdate,
            params: paramsData,
            isToast: false,
            succ: succ,
            fail: fail,
        };

        POST(requestOptions);
    },
    upContractCode(orderCode,contractCode, succ, fail) {
        let requestOptions = {
            path: URL.upContractCode,
            params: {
                orderCode: orderCode,
                contractCode: contractCode
            },
            isLoading: true,
            succ: succ,
            fail: fail
        };
        POST(requestOptions);
    },

};

export default api;