import { openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SingleFieldManager from "@com/SingleFieldManager.vue";
export default {
  __name: 'ContractLabelManager',

  setup(__props) {
    const config = {
      name: '合同类型',
      type: 'contractLabel',
      route: 'contractlabelmanager'
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(SingleFieldManager, {
        config: config
      });
    };
  }

};