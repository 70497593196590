import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "dialog-footer"
};
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { ref } from "vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import { $msg, $box } from "@ui/msg";
import api from "@api/member";
import useRoute from "@util/router";
export default {
  __name: 'MemberTable',
  props: {
    type: {
      type: String
    }
  },
  emits: ['getNum'],

  setup(__props, {
    emit: __emit
  }) {
    const emit = __emit;
    const props = __props;
    const route = useRoute();
    route.watchRouter(props.type, () => {
      getData();
    });
    const data = ref([]);
    const total = ref(0);
    const dialogVisible = ref(false);
    const dialogTitle = ref("");
    const memberLevelChangeForm = ref({
      memberLevel: "",
      memberCode: "",
      memberType: ""
    });
    const memberGradeList = ref([]);

    function memberOperations(data) {
      if (props.type === 'membermanager') {
        // 1009001 普通会员、 1009002 分销会员
        if (data.memberType === '1009001') {
          return [OPERATIONS.check, OPERATIONS.orderRecord, OPERATIONS.convertDistr, OPERATIONS.memberLevelChange];
        } else {
          return [OPERATIONS.check, OPERATIONS.orderRecord];
        }
      } else {
        return [OPERATIONS.check, OPERATIONS.unbindDistr];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: memberOperations
      },
      column: [{
        label: "头像",
        prop: "avatar",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "姓名",
        prop: "nickname"
      }, {
        label: "账号",
        prop: "loginName",
        width: 150
      }, {
        label: "会员状态",
        prop: "memberStatus",
        width: 110,
        type: COLUMN_TYPE.switch,
        operationsKey: OPERATIONS.switch,
        activeText: "正常",
        inactiveText: "封禁",
        activeValue: "1030001",
        // 1030001 正常、 1030002 封禁
        inactiveValue: "1030002",
        switchWidth: 55
      }, {
        label: "性别",
        prop: "gender",
        width: 80
      }, {
        label: "累计消费（元）",
        prop: "totalPayMoney",
        width: 125
      }, {
        label: "会员积分",
        prop: "rewardPoints",
        width: 100
      }, {
        label: "储值余额",
        prop: "depositMoney",
        width: 100,
        type: COLUMN_TYPE.depositMoney,
        operationsKey: OPERATIONS.depositMoney
      }, {
        label: "冻结余额",
        prop: "freezeMoney",
        width: 100,
        type: COLUMN_TYPE.freezeMoney,
        operationsKey: OPERATIONS.freezeMoney
      }, {
        label: "会员标签",
        prop: "memberGrade"
      }, {
        label: "plus会员时效",
        prop: "plusTime",
        width: 100
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 180
      }]
    };

    if (props.type == 'distributormanager') {
      config.column.splice(5, 3);
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      if (props.type === 'membermanager') {
        route.push({
          name: "memberdetails",
          query: {
            code: data.memberCode
          }
        });
      } else {
        route.push({
          name: "distributordetails",
          query: {
            code: data.memberCode
          }
        });
      }
    });
    opHander.install(OP_KEY.orderRecord, data => {
      route.push({
        name: "membergoodsorder",
        query: {
          login: data.loginName
        }
      });
    });
    opHander.install(OP_KEY.convertDistr, data => {
      $box.open("转为分销员确认", `是否将${data.loginName}转为分销员`, () => {
        api.up(data.memberCode, () => {
          $msg.succ("转为分销员成功");
          getData();
          emit('getNum');
        }, () => {
          $msg.succ("转为分销员失败");
        });
      });
    });
    opHander.install(OP_KEY.memberLevelChange, data => {
      console.log(data); // route.push({ name: "memberlevelchange", query: { code: data.memberCode } });

      api.memberGradeList(val => {
        memberGradeList.value = val;
        dialogTitle.value = `会员等级变更 - ${data.loginName}`;
        memberLevelChangeForm.value.memberLevel = data.memberGradeCode;
        memberLevelChangeForm.value.memberCode = data.memberCode;
        memberLevelChangeForm.value.memberType = data.memberType;
        dialogVisible.value = true;
      });
    });
    opHander.install(OP_KEY.unbindDistr, data => {
      $box.open("解除分销员确认", `是否将${data.loginName}解除分销员`, () => {
        api.unbindDistr(data.memberCode, () => {
          $msg.succ("解除分销员成功");
          getData();
          emit('getNum');
        }, () => {
          $msg.succ("解除分销员失败");
        });
      });
    });
    opHander.install(OP_KEY.switch, data => {
      // 1030001 正常、 1030002 封禁
      let status = data.data.memberStatus == "1030001" ? "1030002" : "1030001";
      api.status(data.data.memberCode, status, () => {
        data.resolve(true);
        $msg.succ("用户状态修改成功");
        getData();
      }, () => {
        data.reject();
      });
    });
    opHander.install(OP_KEY.depositMoney, data => {
      route.push({
        name: "deposittabledetails",
        query: {
          code: data.memberCode,
          type: '1048001'
        }
      });
    });
    opHander.install(OP_KEY.freezeMoney, data => {
      route.push({
        name: "deposittabledetails",
        query: {
          code: data.memberCode,
          type: '1048002'
        }
      });
    });

    function submitForm() {
      api.memberGradeChange(memberLevelChangeForm.value, () => {
        $msg.succ("会员等级变更成功");
        dialogVisible.value = false;
        getData();
      });
    }

    getData();

    function getData() {
      let routeQuery = route.query();
      api.list(props.type, {
        loginName: routeQuery.login,
        nickname: routeQuery.name,
        memberStatus: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list;
      });
    }

    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(DataCollectTable, {
        ref: "memberTableRef",
        data: data.value,
        total: total.value,
        config: config,
        onOperationsHander: _unref(opHander).receive
      }, null, 8, ["data", "total", "onOperationsHander"]), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dialogVisible.value = $event),
        title: dialogTitle.value,
        width: "35%",
        top: "35vh",
        class: "base_dialog"
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确认")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[1] || (_cache[1] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref: "memberLevelChangeFormRef",
          model: memberLevelChangeForm.value,
          "label-width": "120px"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "会员等级",
            prop: "memberGradeCode"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: memberLevelChangeForm.value.memberLevel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => memberLevelChangeForm.value.memberLevel = $event),
              placeholder: "请选择会员等级"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(memberGradeList.value, item => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue", "title"])], 64);
    };
  }

};