import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "stock_recode"
};
const _hoisted_2 = {
  class: "dialog-footer"
};
import { ref, reactive } from 'vue';
import useRoute from "@util/router";
import DataCollectTable from "@/components/table/DataCollectTable.vue";
import { COLUMN_TYPE, OP_KEY, OPERATIONS, operationsHander } from "@/components/table/tableOperations";
import { $box, $msg } from "@/common/ui/msg";
import api from "@/api/integral.js";
export default {
  __name: 'IntegralManager',

  setup(__props) {
    const data = ref([]);
    const route = useRoute();
    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "消费金额",
        prop: "money"
      }, {
        label: "获得积分",
        prop: "integral",
        width: 100
      }, {
        label: "状态",
        prop: "integralStatusText",
        width: 100
      }]
    };
    const dialogVisible = ref(false);
    const dataForm = reactive({
      money: "",
      integral: "",
      integralStatus: ""
    });
    const dataFormRef = ref(null);
    const dataFormRules = reactive({
      money: [{
        required: true,
        message: "请输入消费金额",
        trigger: "blur"
      }],
      integral: [{
        required: true,
        message: "请输入获得积分",
        trigger: "blur"
      }],
      integralStatus: [{
        required: true,
        message: "请输入状态",
        trigger: "blur"
      }]
    });

    function getOperations(data) {
      return [OPERATIONS.modify];
    }

    const opHander = operationsHander();
    opHander.install(OP_KEY.modify, data => {
      dataForm.id = data.id;
      dataForm.integral = data.integral;
      dataForm.money = data.money;
      dataForm.integralStatus = data.integralStatus;
      dialogVisible.value = true;
    });
    getData();

    function getData() {
      api.get(res => {
        if (res && res.integralStatus) {
          res.integralStatusText = res.integralStatus == '1001001' ? "启用" : "禁用";
          data.value = [res];
        }
      });
    }

    function submitForm() {
      dataFormRef.value.validate(valid => {
        if (valid) {
          api.update(dataForm, () => {
            $msg.succ("保存成功");
            dialogVisible.value = false;
            getData();
          });
        } else {
          return false;
        }
      });
    }

    return (_ctx, _cache) => {
      const _component_BackTitleView = _resolveComponent("BackTitleView");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_switch = _resolveComponent("el-switch");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _component_el_main = _resolveComponent("el-main");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createVNode(_component_BackTitleView, {
          title: "积分设置"
        }), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          onOperationsHander: _unref(opHander).receive,
          "is-page": false
        }, null, 8, ["data", "onOperationsHander"]), _createVNode(_component_el_dialog, {
          modelValue: dialogVisible.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => dialogVisible.value = $event),
          title: "积分设置",
          width: "35%",
          top: "30vh",
          class: "base_dialog"
        }, {
          footer: _withCtx(() => [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: submitForm
          }, {
            default: _withCtx(() => [_createTextVNode("保存")]),
            _: 1
          }), _createVNode(_component_el_button, {
            onClick: _cache[3] || (_cache[3] = $event => dialogVisible.value = false)
          }, {
            default: _withCtx(() => [_createTextVNode("取消")]),
            _: 1
          })])]),
          default: _withCtx(() => [_createVNode(_component_el_form, {
            ref_key: "dataFormRef",
            ref: dataFormRef,
            model: dataForm,
            rules: dataFormRules,
            "label-width": "100px"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_form_item, {
              label: "消费金额",
              prop: "money"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: dataForm.money,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dataForm.money = $event),
                placeholder: "请输入"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "获得积分",
              prop: "integral"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: dataForm.integral,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => dataForm.integral = $event),
                placeholder: "请输入"
              }, null, 8, ["modelValue"])]),
              _: 1
            }), _createVNode(_component_el_form_item, {
              label: "状态",
              prop: "integralStatus"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_switch, {
                modelValue: dataForm.integralStatus,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dataForm.integralStatus = $event),
                "active-text": "启用",
                "active-value": "1001001",
                "inactive-text": "禁用",
                "inactive-value": "1001002"
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["model", "rules"])]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      })]);
    };
  }

};