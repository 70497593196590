import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "dynamic_params_layout"
};
const _hoisted_3 = {
  class: "pagination_layout"
};
const _hoisted_4 = {
  class: "dialog-footer"
};
import { reactive, ref } from "vue";
import api from "@api/travelArea";
import { $box, $msg } from "@/common/ui/msg";
import travelConfigApi from "@/api/travelConfig";
import $ from "@/common/utils/base";
import useRoute from "@/common/utils/router";
export default {
  __name: 'TravelAreaManager',
  props: {
    config: Object,
    data: Array,
    //数据源
    total: {
      type: Number,
      default: 0
    },
    //总条数
    isRoute: {
      type: Boolean,
      default: true
    },
    // 分页切换是否上路由 需要配合pageChange
    isRadio: {
      type: Boolean,
      default: false
    } // 是否是单选

  },
  emits: ["operationsHander", "pageChange"],

  setup(__props, {
    emit: __emit
  }) {
    const config = {
      name: '产品区域管理',
      type: 'areaConfig',
      route: 'travelAreaConfig'
    };
    const template = {
      detailType: null,
      detailTitle: null,
      startTime: null,
      hotelItems: null
    };
    const travelAreaTableRef = ref(null);
    const data = ref([]);
    const size = ref(20);
    const page = ref(1);
    const total = ref(0);
    const dialogVisible = ref(false);
    const dialogTitle = ref("");
    const formRef = ref(null);
    const provinceOptions = ref([]);
    const countryOptions = ref([]);
    const route = useRoute();
    const props = __props;
    const form = reactive({
      parentInfo: {
        name: null,
        code: null,
        parentCode: null,
        targetAreaList: [],
        templateId: 'A00001'
      },
      name: null,
      parentCode: null,
      id: null
    });
    const rules = {
      name: [{
        required: true,
        message: '请输入区域名称',
        trigger: 'blur'
      }],
      targetAreaList: [{
        required: true,
        message: '请选择目的地',
        trigger: 'blur'
      }]
    };
    /**
     * 操作列 操作按钮事件,
     * operationsHander(type,rowData)
     * 分页栏 事件
     * pageChange
     */

    const emit = __emit;

    function pagerChange(val) {
      if (props.isRoute) {
        route.replaceQuery("p", val);
      } else {
        emit("pageChange", "page", val);
      }
    }

    function pagerSizeChange(val) {
      if (props.isRoute) {
        route.replaceQuery("s", val);
      } else {
        emit("pageChange", "size", val);
      }
    }

    getList();
    getConfig();

    function getConfig() {
      travelConfigApi.province(val => {
        provinceOptions.value = val;
      });
      travelConfigApi.country(val => {
        countryOptions.value = val;
      });
    }

    function getList() {
      api.list({
        page: page.value,
        size: size.value
      }, val => {
        total.value = val.total;

        if (val.parentItemList && val.parentItemList.length > 0) {
          data.value = val.parentItemList.map(item => {
            item.parentId = 0;
            return item;
          });
        } else {
          data.value = val.parentItemList;
        }
      });
    }

    function add(parentInfo = null, type) {
      form.id = null;
      form.name = null;
      form.parentCode = null;
      form.sort = null;

      if (type == 1) {
        form.targetAreaList = null;
      }

      if (type == 2) {
        form.targetAreaList = [];
        form.code = null;
        form.templateId = 'A00001';
      }

      form.parentInfo = parentInfo;
      dialogTitle.value = "新增区域";
      dialogVisible.value = true;
    }

    function update(row) {
      console.log("row" + JSON.stringify(row));
      form.id = row.id;
      form.code = row.code;
      form.name = row.name;
      form.parentCode = row.parentCode;
      form.sort = row.sort;
      form.templateId = row.templateId;

      if ($.isNotEmpty(row.targetAreaList)) {
        form.targetAreaList = row.targetAreaList.split(",").map(e => {
          return e;
        });
        form.parentInfo = {};
      } else {
        form.targetAreaList = null;
        form.parentInfo = null;
      }

      dialogTitle.value = "编辑区域";
      dialogVisible.value = true;
    }

    function del(row) {
      $box.open("删除", "确认删除区域" + row.name, () => {
        api.del(row.code, () => {
          $msg.succ("删除成功");
          getList();
        });
      });
    }

    const submitForm = async formEl => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          const data = {};
          Object.assign(data, form);

          if (data.parentInfo && data.parentInfo.code) {
            data.parentCode = data.parentInfo.code;
          }

          if ($.isNotEmpty(data.targetAreaList)) {
            data.targetAreaList = data.targetAreaList.join(",");
          } else {
            data.targetAreaList = null;
          }

          delete data.parentInfo;

          if (data.code) {
            // 有id为编辑
            api.update(data, () => {
              $msg.succ("修改成功");
              dialogVisible.value = false;
              getList();
            });
          } else {
            // 新增
            api.add(data, () => {
              $msg.succ("新增成功");
              dialogVisible.value = false;
              getList();
            });
          }
        }
      });
    };

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_radio = _resolveComponent("el-radio");

      const _component_el_radio_group = _resolveComponent("el-radio-group");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => add(null, 1))
        }, {
          default: _withCtx(() => [_createTextVNode("新增区域")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]])]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_el_table, {
          ref_key: "travelAreaTableRef",
          ref: travelAreaTableRef,
          data: data.value,
          "row-key": "code",
          border: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            label: "区域编号",
            prop: "code",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "区域名称",
            prop: "name",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "排序",
            prop: "sort",
            align: "center"
          }), _createVNode(_component_el_table_column, {
            label: "操作",
            width: "180",
            align: "center"
          }, {
            default: _withCtx(scope => [scope.row.parentId == 0 ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              size: "small",
              type: "primary",
              text: "",
              onClick: $event => add(scope.row, 2)
            }, {
              default: _withCtx(() => [_createTextVNode(" 添加下级 ")]),
              _: 2
            }, 1032, ["onClick"])), [[_directive_auth, _ctx.getCurCode('ADDNEXT')]]) : _createCommentVNode("", true), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              size: "small",
              type: "primary",
              text: "",
              onClick: $event => update(scope.row)
            }, {
              default: _withCtx(() => [_createTextVNode(" 编辑 ")]),
              _: 2
            }, 1032, ["onClick"])), [[_directive_auth, _ctx.getCurCode('MODIFY')]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
              size: "small",
              type: "danger",
              text: "",
              onClick: $event => del(scope.row)
            }, {
              default: _withCtx(() => [_createTextVNode(" 删除 ")]),
              _: 2
            }, 1032, ["onClick"])), [[_directive_auth, _ctx.getCurCode('DELETE')]])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_pagination, {
          background: "",
          layout: "total, prev, pager, next, sizes",
          total: total.value,
          currentPage: page.value,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = $event => page.value = $event),
          "page-size": size.value,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = $event => size.value = $event),
          "page-sizes": [10, 20, 50, 100],
          onCurrentChange: pagerChange,
          onSizeChange: pagerSizeChange
        }, null, 8, ["total", "currentPage", "page-size"])])]), _createVNode(_component_el_dialog, {
          modelValue: dialogVisible.value,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => dialogVisible.value = $event),
          title: dialogTitle.value,
          width: "35%",
          top: "35vh",
          class: "base_dialog"
        }, {
          footer: _withCtx(() => [_createElementVNode("span", _hoisted_4, [_createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[8] || (_cache[8] = $event => submitForm(formRef.value))
          }, {
            default: _withCtx(() => [_createTextVNode("保存")]),
            _: 1
          }), _createVNode(_component_el_button, {
            onClick: _cache[9] || (_cache[9] = $event => dialogVisible.value = false)
          }, {
            default: _withCtx(() => [_createTextVNode("取消")]),
            _: 1
          })])]),
          default: _withCtx(() => [_createVNode(_component_el_form, {
            model: form,
            "label-width": "92px",
            rules: rules,
            ref_key: "formRef",
            ref: formRef
          }, {
            default: _withCtx(() => [form.parentInfo && form.parentInfo.code ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "上级区域:"
            }, {
              default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(form.parentInfo.name), 1)]),
              _: 1
            })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
              label: "区域名称:",
              prop: "name"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.name,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => form.name = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            }), form.parentInfo ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 1,
              label: "",
              prop: "templateId"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_radio_group, {
                modelValue: form.templateId,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => form.templateId = $event)
              }, {
                default: _withCtx(() => [_createVNode(_component_el_radio, {
                  label: "A00001"
                }, {
                  default: _withCtx(() => [_createTextVNode("境内游")]),
                  _: 1
                }), _createVNode(_component_el_radio, {
                  label: "C00001"
                }, {
                  default: _withCtx(() => [_createTextVNode("出境游")]),
                  _: 1
                })]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            })) : _createCommentVNode("", true), form.parentInfo && form.templateId == 'A00001' ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 2,
              label: "目的地",
              prop: "targetAreaList"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: form.targetAreaList,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => form.targetAreaList = $event),
                multiple: "",
                placeholder: "请选择"
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(provinceOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            })) : _createCommentVNode("", true), form.parentInfo && form.templateId == 'C00001' ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 3,
              label: "目的地",
              prop: "targetAreaList"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_select, {
                modelValue: form.targetAreaList,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => form.targetAreaList = $event),
                multiple: "",
                placeholder: "请选择"
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countryOptions.value, (item, index) => {
                  return _openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 1
              }, 8, ["modelValue"])]),
              _: 1
            })) : _createCommentVNode("", true), _createVNode(_component_el_form_item, {
              label: "排序:",
              prop: "sort"
            }, {
              default: _withCtx(() => [_createVNode(_component_el_input, {
                modelValue: form.sort,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => form.sort = $event)
              }, null, 8, ["modelValue"])]),
              _: 1
            })]),
            _: 1
          }, 8, ["model"])]),
          _: 1
        }, 8, ["modelValue", "title"])]),
        _: 1
      })]);
    };
  }

};