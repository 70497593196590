import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { defineComponent, ref } from 'vue';
import VueOfficePdf from '@vue-office/pdf';
export default {
  __name: 'LockFileView',
  props: {
    components: {
      VueOfficePdf
    },
    title: {
      type: String,
      default: ""
    },

    data() {
      return {
        pdfUrl: "https://cuiweiimg.oss-cn-beijing.aliyuncs.com/img/20241025/586e83474bf34e4eb5075a7e58456669.pdf"
      };
    }

  },

  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(VueOfficePdf), {
        src: __props.title,
        style: {
          "height": "100vh",
          "border": "none",
          "box-shadow": "none"
        }
      }, null, 8, ["src"]);
    };
  }

};