import { POST } from "@net/service.js";
import $ from "@util/base";
import page from "./page"

const URL = {
    num: "promotion_activity_status_num",
    list: "promotion_activity_list",
    add: "promotion_activity_add",
    clone: "promotion_activity_clone",
    delete: "promotion_activity_delete",
    details: "promotion_activity_detail",
    status: "promotion_activity_status_change",
    update: "promotion_activity_update",
    export: "promotion_activity_list_export"
};

const api = {
    num(succ) {
        let requestOptions = {
            path: URL.num,
            succ: val => {
                succ([val.total, val.create, val.issue, val.abort, val.end])
            }
        }
        POST(requestOptions)
    },
    export (params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.limitBusiType) && params.limitBusiType != 'all') {
            paramsData.limitBusiType = params.limitBusiType
        }
        let requestOptions = {
            path: URL.export,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    list(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.name)) {
            paramsData.name = params.name
        }
        if ($.isNotEmpty(params.limitBusiType) && params.limitBusiType != 'all') {
            paramsData.limitBusiType = params.limitBusiType
        }
        if ($.isNotEmpty(params.activityStatus) && params.activityStatus != 'all') {
            paramsData.activityStatus = params.activityStatus
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.list,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    add(submitType, params, succ) {
        let paramsData = {}
        paramsData.submitType = parseInt(submitType)
        paramsData.name = params.name
        paramsData.startDate = params.date[0]
        paramsData.endDate = params.date[1]
        paramsData.mainPic = params.mainPic[0]
        paramsData.itemPic = params.itemPic[0]
        paramsData.intro = params.intro
        paramsData.moneyType = params.moneyType
        paramsData.money = params.moneyType === "1010001" ? params.moneyOff.money : params.discountMoney
        paramsData.limitMoney = params.moneyType === "1010001" ? params.moneyOff.limitMoney : 0
        paramsData.limitBusiType = params.limitBusiType
        paramsData.codeList = params.codeList
        console.log("paramsData"+JSON.stringify(paramsData));
        let requestOptions = {
            path: URL.add,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    update(code, submitType, params, succ) {
        let paramsData = {}
        paramsData.activityCode = code
        paramsData.submitType = parseInt(submitType)
        paramsData.name = params.name
        paramsData.startDate = params.date[0]
        paramsData.endDate = params.date[1]
        paramsData.mainPic = params.mainPic[0]
        paramsData.itemPic = params.itemPic[0]
        paramsData.intro = params.intro
        paramsData.moneyType = params.moneyType
        paramsData.money = params.moneyType === "1010001" ? params.moneyOff.money : params.discountMoney
        paramsData.limitMoney = params.moneyType === "1010001" ? params.moneyOff.limitMoney : 0
        paramsData.limitBusiType = params.limitBusiType
        paramsData.codeList = params.codeList
        let requestOptions = {
            path: URL.update,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    clone(code, succ) {
        let requestOptions = {
            path: URL.clone,
            params: { activityCode: code },
            succ: succ
        }
        POST(requestOptions)
    },
    delete(code, succ) {
        let requestOptions = {
            path: URL.delete,
            params: { activityCode: code },
            succ: succ
        }
        POST(requestOptions)
    },
    details(code, succ) {
        let requestOptions = {
            path: URL.details,
            params: { activityCode: code },
            succ: val => {
                let transitionData = {
                    moneyOff: {}
                }
                transitionData.name = val.activityName
                transitionData.date = [val.startDate, val.endDate]
                transitionData.mainPic = [val.mainPic]
                transitionData.itemPic = [val.itemPic]
                if (val.moneyType === '1010001') {
                    transitionData.moneyOff.limitMoney = val.limitMoney
                    transitionData.moneyOff.money = val.money
                } else {
                    transitionData.discountMoney = val.money
                }
                let mergeData = Object.assign(val, transitionData)
                succ(mergeData)
            }
        }
        POST(requestOptions)
    },
    status(code, status, succ) {
        let paramsData = {}
        paramsData.activityCode = code
        paramsData.activityStatus = status
        let requestOptions = {
            path: URL.status,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
}

export default api