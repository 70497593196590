import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "base_screen_header"
};
const _hoisted_2 = {
  class: "base_main_header"
};
import StatusBar from "@com/StatusBar.vue";
import { $msg, $box } from "@ui/msg";
import { ref, reactive } from "vue";
import { Search } from "@element-plus/icons-vue";
import useRoute from "@util/router";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import { OP_KEY, COLUMN_TYPE, OPERATIONS, operationsHander } from "@com/table/tableOperations";
import $ from "@util/base";
import api from "@api/promotions";
export default {
  __name: 'PromotionsManager',

  setup(__props) {
    const route = useRoute();
    route.watchRouter("promotionsmanager", () => {
      getData();
    });
    const statusList = reactive([{
      label: "全部",
      value: "all",
      total: 0
    }, {
      label: "未发布",
      value: "1032001",
      total: 0
    }, {
      label: "已发布",
      value: "1032002",
      total: 0
    }, {
      label: "已终止",
      value: "1032003",
      total: 0
    }, {
      label: "已结束",
      value: "1032004",
      total: 0
    }]);
    const data = ref([]);
    const total = ref(0);

    function getOperations(data) {
      switch (data.activityStatus) {
        // 1032001 未发布、1032002 已发布、1032003 已终止、1032004 已结束
        case "1032001":
          return [OPERATIONS.check, OPERATIONS.modify, // OPERATIONS.copy,
          OPERATIONS.del, OPERATIONS.publish, OPERATIONS.add2];

        case "1032002":
          return [OPERATIONS.check, // OPERATIONS.copy,
          OPERATIONS.stop, OPERATIONS.cancelPublish, OPERATIONS.add2];

        case "1032003":
          return [OPERATIONS.check, // OPERATIONS.copy,
          OPERATIONS.add2];

        case "1032004":
          return [OPERATIONS.check, OPERATIONS.add2];

        default:
          return [OPERATIONS.check, // OPERATIONS.copy, 
          OPERATIONS.del];
      }
    }

    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 120,
        operations: getOperations
      },
      column: [{
        label: "活动图片",
        prop: "mainPic",
        type: COLUMN_TYPE.picture,
        width: 100
      }, {
        label: "活动名称",
        prop: "name"
      }, {
        label: "活动时间",
        prop: "date",
        width: 300
      }, {
        label: "活动状态",
        prop: "activityStatusStr",
        width: 100
      }, {
        label: "产品类型",
        prop: "limitBusiTypeStr",
        width: 100
      }, {
        label: "优惠内容",
        prop: "couponDesc",
        width: 120
      }, {
        label: "创建时间",
        prop: "createTime",
        width: 100
      }, {
        label: "创建人",
        prop: "creator",
        width: 100
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.check, data => {
      route.push({
        name: "promotionsdetails",
        query: {
          type: "show",
          code: data.activityCode
        }
      });
    });
    opHander.install(OP_KEY.modify, data => {
      route.push({
        name: "promotionsdetails",
        query: {
          type: "modify",
          code: data.activityCode
        }
      });
    });
    opHander.install(OP_KEY.add2, data => {
      route.push({
        name: "promotionsdetails",
        query: {
          type: "add2",
          code: data.activityCode
        }
      });
    });
    opHander.install(OP_KEY.delete, data => {
      $box.open("删除确认", `是否删除${data.name}活动`, () => {
        api.delete(data.activityCode, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.copy, data => {
      $box.open("复制确认", `是否复制${data.name}活动`, () => {
        api.clone(data.activityCode, () => {
          $msg.succ("复制成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.cancelPublish, data => {
      $box.open("取消发布确认", `是否取消发布${data.name}活动`, () => {
        api.status(data.activityCode, "1032001", () => {
          $msg.succ("取消发布成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.publish, data => {
      $box.open("发布确认", `是否发布${data.name}活动`, () => {
        api.status(data.activityCode, "1032002", () => {
          $msg.succ("发布成功");
          getData();
        });
      });
    });
    opHander.install(OP_KEY.stop, data => {
      $box.open("终止确认", `是否终止${data.name}活动`, () => {
        api.status(data.activityCode, "1032003", () => {
          $msg.succ("终止成功");
          getData();
        });
      });
    });

    function add() {
      route.push({
        name: "promotionsdetails",
        query: {
          type: "add"
        }
      });
    }

    const typeOptions = [{
      label: "商品",
      value: "1003001"
    }, {
      label: "旅行",
      value: "1003002"
    }, {
      label: "酒店",
      value: "1003003"
    }];
    const type = ref(route.query().type || "");
    const name = ref(route.query().name || "");

    function searchType(val) {
      route.replaceQuery("type", val);
    }

    function searchName(val) {
      route.replaceQuery("name", val);
    }

    getData();

    function getData() {
      getList();
      getNum();
    }

    function getList() {
      let routeQuery = route.query();
      api.list({
        name: routeQuery.name,
        limitBusiType: routeQuery.type,
        activityStatus: routeQuery.status,
        page: routeQuery.p,
        size: routeQuery.s
      }, val => {
        total.value = val.total;
        data.value = val.list.map(e => {
          e.date = `${e.startDate}-${e.endDate}`; // 1003001 商品、1003002 旅行、1003003 酒店

          switch (e.limitBusiType) {
            case "1003001":
              e.limitBusiTypeStr = "商品";
              break;

            case "1007002":
              e.limitBusiTypeStr = "旅行";
              break;

            case "1007003":
              e.limitBusiTypeStr = "酒店";
              break;
          } // 1032001 未发布、1032002 已发布、1032003 已终止、1032004 已结束


          switch (e.activityStatus) {
            case "1032001":
              e.activityStatusStr = "未发布";
              break;

            case "1032002":
              e.activityStatusStr = "已发布";
              break;

            case "1032003":
              e.activityStatusStr = "已终止";
              break;

            case "1032004":
              e.activityStatusStr = "已结束";
              break;
          }

          return e;
        });
      });
    }

    function getNum() {
      api.num(val => {
        statusData(val);
      });
    }

    function statusData(val) {
      statusList.forEach((e, i) => {
        e.total = val[i];
      });
    }

    function getExport() {
      let routeQuery = route.query();
      api.export({
        name: routeQuery.name,
        limitBusiType: routeQuery.type
      }, val => {
        $.download(val.url, "优惠活动导出");
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_main = _resolveComponent("el-main");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createBlock(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: add
        }, {
          default: _withCtx(() => [_createTextVNode("新增优惠活动")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('ADD')]]), _createVNode(_component_el_form, {
          inline: true,
          class: "base_search_form",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              modelValue: type.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => type.value = $event),
              onChange: searchType,
              placeholder: "请选择产品",
              clearable: ""
            }, {
              default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(typeOptions, (item, index) => {
                return _createVNode(_component_el_option, {
                  key: index,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]);
              }), 64))]),
              _: 1
            }, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: name.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => name.value = $event),
              "onKeyup[native]": searchName,
              onChange: searchName,
              placeholder: "活动名称",
              clearable: "",
              "suffix-icon": _unref(Search)
            }, null, 8, ["modelValue", "suffix-icon"])]),
            _: 1
          })]),
          _: 1
        })]), _createVNode(StatusBar, {
          data: statusList,
          default: "all"
        }, null, 8, ["data"]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          text: "",
          onClick: getExport
        }, {
          default: _withCtx(() => [_createTextVNode("导出")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('EXPORT')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      });
    };
  }

};