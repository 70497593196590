// 财务
import TransactionDetail from '@/views/finance/TransactionDetail.vue'
import DetailsView from '@/views/finance/detailsView.vue'
import DepositManager from '@/views/finance/DepositManager.vue'
import DepositDetails from '@/views/finance/DepositDetails.vue'
import DepositMoneyList from '@/views/finance/DepositMoneyList.vue'
import DepositeCard from '@/views/finance/DepositeCard.vue'

import BatchDepositImport from '@/views/finance/BatchDepositImport.vue'
import CashierManager from '@/views/finance/CashierManager.vue'
import InvoiceManager from '@/views/finance/InvoiceManager.vue'
import ContractManager from '@/views/finance/ContractManager.vue'
import BaseManager from '@/views/finance/BaseManager.vue'

import PointsManager from '@/views/finance/PointsManager.vue'
import PointsDetails from '@/views/finance/PointsDetails.vue'
import BatchPointsImport from '@/views/finance/BatchPointsImport.vue'

import StockManager from '@/views/finance/StockManager.vue'
import GoodsStockRecode from '@/views/finance/GoodsStockRecode.vue'
import HotelStockDetails from "@/views/finance/HotelStockDetails.vue"
import TravelStockDetails from "@/views/finance/TravelStockDetails.vue"

import ContractLabelManager from "@/views/finance/ContractLabelManager.vue"
import ContractImport from "@/views/finance/ContractImport.vue"


// 积分管理
import IntegralManager from '@/views/integral/IntegralManager.vue'
import IntegralProduct from '@/views/integral/IntegralProduct.vue'
import IntegralProductDetails from '@/views/integral/IntegralProductDetails.vue'
import IntegralHotel from '@/views/integral/IntegralHotel.vue'
import IntegralHotelDetails from '@/views/integral/IntegralHotelDetails.vue'
import IntegralTravel from '@/views/integral/IntegralTravel.vue'
import IntegralTravelDetails from '@/views/integral/IntegralTravelDetails.vue'
import LabelManager from "@/views/goods/LabelManager";

export default [
    {
        path: '/transactiondetail',
        name: 'transactiondetail',
        component: TransactionDetail,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/transactiondetail'
        }
    }, {
        path: '/detailsview',
        name: 'detailsview',
        component: DetailsView,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/transactiondetail'
        }
    }, {
        path: '/depositmanager',
        name: 'depositmanager',
        component: DepositManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/depositmanager'
        }
    }, {
        path: '/depositdetails',
        name: 'depositdetails',
        component: DepositDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/depositmanager'
        }
    }, {
        path: '/depositecard',
        name: 'depositecard',
        component: DepositeCard,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/depositmanager'
        }
    }, {
        path: '/depositmoneylist',
        name: 'depositmoneylist',
        component: DepositMoneyList,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/depositmanager'
        }
    }, {
        path: '/batchdepositimport',
        name: 'batchdepositimport',
        component: BatchDepositImport,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/depositmanager'
        }
    }, {
        path: '/pointsmanager',
        name: 'pointsmanager',
        component: PointsManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/pointsmanager'
        }
    }, {
        path: '/pointsdetails',
        name: 'pointsdetails',
        component: PointsDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/pointsdetails'
        }
    }, {
        path: '/batchpointsimport',
        name: 'batchpointsimport',
        component: BatchPointsImport,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/batchpointsimport'
        }
    }, {
        path: '/batchdepositimport',
        name: 'batchdepositimport',
        component: BatchDepositImport,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/depositmanager'
        }
    }, {
        path: '/financestockmanager',
        name: 'financestockmanager',
        component: StockManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/financestockmanager'
        }
    }, {
        path: '/goodsstockrecode',
        name: 'goodsstockrecode',
        component: GoodsStockRecode,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/financestockmanager'
        }
    }, {
        path: '/hotelstockdetails',
        name: 'hotelstockdetails',
        component: HotelStockDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/financestockmanager'
        }
    }, {
        path: '/travelstockdetails',
        name: 'travelstockdetails',
        component: TravelStockDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/financestockmanager,/hotelstocksmanager'
        }
    }, {
        path: '/cashiermanager',
        name: 'cashiermanager',
        component: CashierManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/cashiermanager'
        }
    }, {
        path: '/invoicemanager',
        name: 'invoicemanager',
        component: InvoiceManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/invoicemanager'
        }
    }, {
        path: '/contractmanager',
        name: 'contractmanager',
        component: ContractManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/contractmanager'
        }
    }, {
        path: "/contractlabelmanager",
        name: "contractlabelmanager",
        component: ContractLabelManager,
        meta: {
            activeMainMenu: "finance",
            activeSubMenu: "/contractlabelmanager",
        },
    }, {
        path: '/contractimport',
        name: 'contractimport',
        component: ContractImport,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/contractimport'
        }
    }, {
        path: '/basemanager2',
        name: 'basemanager2',
        component: BaseManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/basemanager2'
        }
    }, {
        path: '/integralManager',
        name: 'integralManager',
        component: IntegralManager,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralManager'
        }
    },
    {
        path: '/integralProduct',
        name: 'integralProduct',
        component: IntegralProduct,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralProduct'
        }
    },
    {
        path: '/integralProductDetails',
        name: 'integralProductDetails',
        component: IntegralProductDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralProduct'
        }
    },
    {
        path: '/integralHotel',
        name: 'integralHotel',
        component: IntegralHotel,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralHotel'
        }
    },
    {
        path: '/integralHotelDetails',
        name: 'integralHotelDetails',
        component: IntegralHotelDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralHotel'
        }
    },
    {
        path: '/integralTravel',
        name: 'integralTravel',
        component: IntegralTravel,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralTravel'
        }
    },
    {
        path: '/integralTravelDetails',
        name: 'integralTravelDetails',
        component: IntegralTravelDetails,
        meta: {
            activeMainMenu: 'finance',
            activeSubMenu: '/integralTravel'
        }
    }
]
