import {
    POST
} from '@net/service.js'
import $ from "@util/base"
import configApi from "./config"
import page from "./page"

const URL = {
    stockChangeRecode: 'product_sku_stock_change_list',
    skuStock: 'product_sku_stock_list',
    stockChange: 'product_sku_stock_change_add',
    stockExport: 'product_sku_stock_list_export',
    stockChangeExport: 'product_sku_stock_change_list_export',
    downloadTemplate: 'download_product_sku_stock_change_template',
    importStock: 'product_sku_stock_change_list_import',
    costUpdate: 'product_sku_cost_price_update'
}

const api = {
    base(succ) {
        configApi.base(succ)
    },
    changeType(succ) {
        configApi.dict("1005", succ)
    },
    stockChangeRecode(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.changeType) && params.changeType != "all") {
            paramsData.changeType = params.changeType
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        if ($.isNotEmpty(params.sku) && $.isEmpty(params.productName)) {
            paramsData.skuCode = params.sku
        }

        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.stockChangeRecode,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    skuStock(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.skuCode)) {
            paramsData.skuCode = params.skuCode
        }
        if ($.isNotEmpty(params.productCode)) {
            paramsData.productCode = params.productCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        paramsData.page = params.page ? params.page : 1
        paramsData.pageTotal = params.size ? parseInt(params.size) : page.size
        let requestOptions = {
            path: URL.skuStock,
            params: paramsData,
            isLoading: true,
            succ: succ
        }
        POST(requestOptions)
    },
    stockChange(params, succ) {
        let paramsData = {
            productCode: params.productCode,
            skuCode: params.skuCode,
            changeNum: params.changeNum,
            changeFlag: params.changeFlag,
            changeType: "1005003" //先写死
        }
        if ($.isNotEmpty(params.remark)) {
            paramsData.remark = params.remark
        }

        let requestOptions = {
            path: URL.stockChange,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    stockExport(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.skuCode)) {
            paramsData.skuCode = params.skuCode
        }
        if ($.isNotEmpty(params.productCode)) {
            paramsData.productCode = params.productCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        if ($.isNotEmpty(params.date)) {
            paramsData.startTime = params.date[0]
            paramsData.endTime = params.date[1]
        }
        let requestOptions = {
            path: URL.stockExport,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    stockChangeExport(params, succ) {
        let paramsData = {}
        if ($.isNotEmpty(params.changeType) && params.changeType != "all") {
            paramsData.changeType = params.changeType
        }
        if ($.isNotEmpty(params.shopCode) && params.shopCode != "all") {
            paramsData.shopCode = params.shopCode
        }
        if ($.isNotEmpty(params.productName)) {
            paramsData.productName = params.productName
        }
        if ($.isNotEmpty(params.skuCodes)) {
            paramsData.skuCodes = params.skuCodes
        }
        let requestOptions = {
            path: URL.stockChangeExport,
            params: paramsData,
            succ: succ
        }
        POST(requestOptions)
    },
    downloadTemplate(succ) {
        let requestOptions = {
            path: URL.downloadTemplate,
            succ: succ
        }
        POST(requestOptions)
    },
    importStock(file, succ) {
        let requestOptions = {
            path: URL.importStock,
            params: { fileUrl: file },
            succ: succ
        }
        POST(requestOptions)
    },
    costUpdate(price, sku, succ) {
        let requestOptions = {
            path: URL.costUpdate,
            params: { costPrice: price, skuCode: sku },
            succ: succ
        }
        POST(requestOptions)
    }
}

export default api