import {
  POST
} from '@net/service.js'

const URL = {
  add: 'postage_templete_add',
  details: 'postage_templete_get',
  update: 'postage_templete_update',
  chooseArea: 'choose_area',
  chooseCountry: 'choose_country'
}

const api = {
  add(form, priceData, succ) {
    let params = {
      name: form.name,
      money: form.money,
      itemList: priceData.map((e, index) => {
        e.seqId = index + 1
        e.areaCode = e.areaItemList.map(elem => {
          return elem.areaCode
        }).join(",");
        delete e.areaItemList
        return e
      })
    }
    let requestOptions = {
      path: URL.add,
      params: params,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  update(id, form, priceData, succ) {
    let params = {
      id: parseInt(id),
      name: form.name,
      money: form.money,
      itemList: priceData.map((e, index) => {
        e.seqId = index + 1
        e.areaCode = e.areaItemList.map(elem => {
          return elem.areaCode
        }).join(",");
        delete e.areaItemList
        return e
      })
    }
    let requestOptions = {
      path: URL.update,
      params: params,
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  details(id, succ) {
    let requestOptions = {
      path: URL.details,
      params: { id: parseInt(id) },
      isLoading: true,
      succ: succ
    }
    POST(requestOptions)
  },
  chooseArea(succ) {
    let requestOptions = {
      path: URL.chooseArea,
      params: { level: 1 },
      succ: val => {
        succ(val.itemList.map(e => {
          return { areaCode: e.code, areaName: e.name }
        }))
      }
    }
    POST(requestOptions)
  },
  chooseCountry(succ) {
    let requestOptions = {
      path: URL.chooseCountry,
      params: { level: 1 },
      succ: val => {
        succ(val.itemList.map(e => {
          return { areaCode: e.code, areaName: e.name }
        }))
      }
    }
    POST(requestOptions)
  }
}

export default api