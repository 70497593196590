import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "stock_recode"
};
const _hoisted_2 = {
  class: "base_screen_header"
};
const _hoisted_3 = {
  class: "dialog-footer"
};
import BackTitleView from "@com/BackTitleView.vue";
import DataCollectTable from "@com/table/DataCollectTable.vue";
import useRoute from "@util/router";
import $ from "@util/base";
import api from "@api/memberGrade";
import { COLUMN_TYPE, OP_KEY, OPERATIONS, operationsHander } from "@/components/table/tableOperations";
import { reactive, ref } from "vue";
import { $box, $msg } from "@/common/ui/msg";
export default {
  __name: 'MemberList',

  setup(__props) {
    const route = useRoute();

    function goodsOperations(data) {
      return [OPERATIONS.del];
    }

    const total = ref(0);
    const data = ref([]);
    const config = {
      showIndex: true,
      operations: {
        label: "操作",
        width: 130,
        operations: goodsOperations
      },
      column: [{
        label: "姓名",
        prop: "nickname"
      }, {
        label: "头像",
        prop: "avatar",
        type: COLUMN_TYPE.picture
      }, {
        label: "账号",
        prop: "loginName"
      }, {
        label: "账号创建时间",
        prop: "createTime"
      }, {
        label: "失效时间",
        prop: "expiringTime",
        width: 180
      }]
    };

    const resetForm = formEl => {
      if (!formEl) return;
      formEl.resetFields();
    };

    const dialogVisible = ref(false);
    const memberTagsFormRef = ref('');
    const memberTagsForm = reactive({
      phone: null,
      tags: route.query().tags,
      expiringTime: null
    });
    const memberTagsRules = {
      phone: [{
        required: true,
        message: '请输入手机号',
        trigger: 'blur'
      }],
      expiringTime: [{
        required: true,
        message: '请输入失效时间',
        trigger: 'blur'
      }]
    };
    const opHander = operationsHander();
    opHander.install(OP_KEY.delete, data => {
      console.log("data" + JSON.stringify(data));
      $box.open("删除", "确认删除该标签用户?", () => {
        api.memberListDel({
          tags: data.tags,
          memberCode: data.memberCode
        }, () => {
          $msg.succ("删除成功");
          getData();
        });
      });
    });
    getData();
    route.watchRouter("memberList", () => {
      getData();
    });
    var title = '';
    init();

    function init() {
      title = route.query().name + "-会员列表";
    }

    function getData() {
      let routeQuery = route.query();
      api.memberList({
        tags: routeQuery.tags
      }, val => {
        data.value = val.itemList;
        total.value = val.total;
      });
    }

    function add() {
      dialogVisible.value = true;
    }

    function subimt(formEl) {
      formEl.validate((valid, fields) => {
        let routeQuery = route.query();
        api.memberListAdd({
          tags: routeQuery.tags,
          phone: memberTagsForm.phone,
          expiringTime: memberTagsForm.expiringTime
        }, val => {
          $msg.succ("添加成功");
          dialogVisible.value = false;
          formEl.resetFields();
          getData();
        });
      });
    }

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_main = _resolveComponent("el-main");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_date_picker = _resolveComponent("el-date-picker");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      const _directive_auth = _resolveDirective("auth");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_main, {
        class: "base_main_content"
      }, {
        default: _withCtx(() => [_createVNode(BackTitleView, {
          title: _unref(title)
        }, null, 8, ["title"]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = $event => add())
        }, {
          default: _withCtx(() => [_createTextVNode("添加用户")]),
          _: 1
        })), [[_directive_auth, _ctx.getCurCode('lockMemberAdd')]])]), _createVNode(DataCollectTable, {
          data: data.value,
          config: config,
          total: total.value,
          onOperationsHander: _unref(opHander).receive
        }, null, 8, ["data", "total", "onOperationsHander"])]),
        _: 1
      }), _createVNode(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => dialogVisible.value = $event),
        title: "添加用户",
        width: "30%",
        top: "30vh",
        class: "base_dialog",
        onOpen: _cache[6] || (_cache[6] = $event => resetForm(memberTagsFormRef.value))
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[3] || (_cache[3] = $event => subimt(memberTagsFormRef.value))
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: _cache[4] || (_cache[4] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_form, {
          ref_key: "memberTagsFormRef",
          ref: memberTagsFormRef,
          model: memberTagsForm,
          rules: memberTagsRules
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: "用户手机号",
            prop: "phone"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: memberTagsForm.phone,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => memberTagsForm.phone = $event)
            }, null, 8, ["modelValue"])]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "失效时间",
            prop: "expiringTime"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_date_picker, {
              modelValue: memberTagsForm.expiringTime,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => memberTagsForm.expiringTime = $event),
              type: "date",
              "value-format": "YYYY-MM-DD"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};